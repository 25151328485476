import React from "react";
import { Link } from "gatsby";

import { PressCType } from "../../assets/Types/AcfGroupTypes/Press";
import Container from "../../components/UI/Container";
import WysiwygComponent from "../../components/UI/WysiwygComponent";
import HorizontalRule from "../../components/UI/HorizontalRule";
import ArrowBack from "../../components/SVGIcons/ArrowBack";

import styles from "./PressDetailsContainer.module.scss";

export type PressDetailsContainerProps = {
  title: string;
  pressContent: PressCType;
};

const PressDetailsContainer: React.FC<PressDetailsContainerProps> = ({
  title,
  pressContent,
}) => {
  const {
    pressWysiwyg,
    pressImage: {
      altText,
      localFile: { publicURL },
    },
  } = pressContent;

  return (
    <Container variant="narrow">
      <header className={styles.pressHeader}>
        <Link
          className={styles.pressGoBack}
          to="/press"
          data-testid="press-details-goBack-btn"
        >
          <ArrowBack />
          Press
        </Link>
        <HorizontalRule variant="hr--16" />
        <h1 className="h1" data-testid="press-details-title">
          {title}
        </h1>
      </header>

      <img
        className={styles.pressImage}
        src={publicURL}
        alt={altText}
        data-testid="press-details-image"
      />
      <HorizontalRule variant="hr--48" />

      <WysiwygComponent
        wysiwyg={pressWysiwyg}
        data-testid="press-details-wysiwyg"
      />
    </Container>
  );
};

export default PressDetailsContainer;
