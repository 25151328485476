import React from "react";
import { HorizontalRuleProps } from "./HorizontalRule.types";

import hrStyles from "./HorizontalRule.module.scss";

const HorizontalRule: React.FC<HorizontalRuleProps> = ({ variant }) => {
  const options = {
    "hr--4": hrStyles.hr__4,
    "hr--8": hrStyles.hr__8,
    "hr--10": hrStyles.hr__10,
    "hr--12": hrStyles.hr__12,
    "hr--16": hrStyles.hr__16,
    "hr--20": hrStyles.hr__20,
    "hr--24": hrStyles.hr__24,
    "hr--32": hrStyles.hr__32,
    "hr--40": hrStyles.hr__40,
    "hr--48": hrStyles.hr__48,
    "hr--58": hrStyles.hr__58,
    "hr--64": hrStyles.hr__64,
    "hr--80": hrStyles.hr__80,
    "hr--96": hrStyles.hr__96,
  };
  return <span className={`${hrStyles["hr"]} ${options[variant]}`}></span>;
};

export default HorizontalRule;
