import React from "react";
import parse from "html-react-parser";

import styles from "./WysiwygComponent.module.scss";

type WysiwygComponentProps = {
  wysiwyg: string;
  [key: string]: any;
};

const WysiwygComponent: React.FC<WysiwygComponentProps> = ({
  wysiwyg,
  ...rest
}) => {
  const wysiwygContent = parse(wysiwyg || "", {
    replace: node => node,
  });

  return (
    <div className={styles.wysiwygWrapper} {...rest}>
      {wysiwygContent}
    </div>
  );
};

export default WysiwygComponent;
