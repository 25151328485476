import * as React from "react";
import { graphql, PageProps } from "gatsby";
import MainLayout from "../../layouts/main";
import { PressDetailsData } from "../../assets/Types/AcfGroupTypes/Press";
import PressDetailsContainer from "../../containers/PressDetailsContainer";
import SEO from "gatsby-plugin-wpgraphql-seo";
import { deIndexSeo } from "../../utils/deIndexSeo";

const PressDetailsPage: React.FC<PageProps<PressDetailsData>> = ({ data }) => {
  const { wpPressSingle } = data;
  const { title, pressC } = wpPressSingle;

  return (
    <MainLayout hasFooterLargeCta>
      {wpPressSingle.seo && (
        <SEO post={deIndexSeo(wpPressSingle as unknown as Queries.WpPage)} />
      )}
      <PressDetailsContainer title={title} pressContent={pressC} />
    </MainLayout>
  );
};

export default PressDetailsPage;

export const query = graphql`
  query PressDetailQuery($id: String!) {
    wpPressSingle(id: { eq: $id }) {
      title
      uri
      seo {
        title
        metaDesc
        focuskw
        metaKeywords
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphImage {
          altText
          sourceUrl
          srcSet
        }
        twitterTitle
        twitterDescription
        twitterImage {
          altText
          sourceUrl
          srcSet
        }
        canonical
        cornerstone
        schema {
          articleType
          pageType
          raw
        }
      }
      pressC {
        pressWysiwyg
        pressImage {
          altText
          localFile {
            publicURL
          }
          uri
        }
      }
    }
  }
`;
